import React, { useEffect, useContext, useState } from "react";
import Axios from "axios";
import { notification, Button, Form, Input } from "antd";
import { UserContext } from "../../App";
import { SERVER_URL } from "../../config";
import TextArea from "antd/lib/input/TextArea";

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8 },
};

const Email = (props) => {
  const currentuser = useContext(UserContext);
  const [form] = Form.useForm();
  const [header, setHeader] = useState("");
  const [content, setContent] = useState("");
  const [content2, setContent2] = useState("");

  useEffect(() => {
    const fetchEmail = async () => {
      try {
        const resEmail = await Axios.get(`${SERVER_URL}/orders-email`);
        setHeader(resEmail.data.email[0].header);
        setContent(resEmail.data.email[0].content);
        setContent2(resEmail.data.email[0].emailContent);
        form.setFieldsValue({
          header: resEmail.data.email[0].header,
          content: resEmail.data.email[0].content,
          content2: resEmail.data.email[0].emailContent,
        });
      } catch (error) {
        console.log("ERROR: ", error);
      }
    };

    fetchEmail();
  }, []);

  const initialValues = { header, content, content2 };

  const html = `
    <html xmlns="http://www.w3.org/1999/xhtml" style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; height: 100%; width: 100%; margin: 0 auto; padding: 0;">
             <body width="100%" style='-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; height: 100%; width: 100%; font-family: "Helvetica Neue", sans-serif; margin: 0 auto; padding: 0; bgcolor=#fff; color: #444'>
             <div style="width: 600px; margin: 0 auto;">
              <p style="text-align:right; margin-right:28px">
                            <a class="url" rel="contact" title="Himtex" target="_blank" utm_content="user-avatar-image" href="https://himtexcompany.com/" style='-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; font-family: "Helvetica Neue", sans-serif; color: #3a8bbb; font-weight: 500; text-decoration: none;'><img class="photo" alt="Himtex" width="55" height="48" src="https://s3.eu-central-1.amazonaws.com/universal-api-test/images/1695297434485_logo_himtex_mail.jpg" style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; height: auto; line-height: 100%; outline: none; text-decoration: none; -ms-interpolation-mode: bicubic; display: inline; width: 95px; border: 0;"></a></p>
                <div style="width:100%;text-align:left!important;">
                    <span style="font-family:Verdana;font-size:15px;color:#444;text-decoration:none;text-align:left;">
                      <h1 style="font-size: 18px; line-height: 1.3; font-weight: 500">${header},</h1>
                      <p>${content}</p>
                    </span>
                  </div>
                </div>
             </body>
             </html>
  `;

  const onFinish = async () => {
    try {
      await Axios.post(
        `${SERVER_URL}/orders-email`,
        { html, header, content },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );
      notification.success({
        message: `Email updated.`,
        placement: "bottomRight",
      });
      setTimeout(() => {
        window.location.href = `/admin/orders-processed-email`;
      }, 1200);
    } catch (error) {
      const msg = error.response
        ? error.response.data.message
        : error.message
        ? error.message
        : error;
      notification.error({
        message: msg,
        placement: "bottomRight",
      });
    }
  };

  return (
    <div className="panel panel-body">
      <div className="panel-body">
        <Form
          className="form-horizontal"
          layout={formItemLayout}
          initialValues={initialValues}
          onFinish={onFinish}
          form={form}
        >
          <Form.Item
            style={{ marginRight: "20px" }}
            label="Header"
            name="header"
            onChange={(e) => setHeader(e.target.value)}
          >
            <Input />
          </Form.Item>

          <Form.Item
            style={{ marginRight: "20px" }}
            label="Content"
            name="content"
            onChange={(e) => setContent(e.target.value)}
          >
            <TextArea rows={10} />
          </Form.Item>

          <div dangerouslySetInnerHTML={{ __html: content2 }}></div>

          <Button type="primary" htmlType="submit">
            Izmeni Email
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default Email;
